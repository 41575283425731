/* eslint-disable react/prop-types */
import React from 'react';
import { useGoogleLogin } from 'react-google-login';
import { setCookie, deleteCookie } from '../utils/jsHelpers';

const AuthContext = React.createContext({});

export const useAuthContext = () => React.useContext(AuthContext);

export function AuthContextWrapper({ children }) {
  const [loading, setLoading] = React.useState(true);
  const clientId = '486262829158-3c1ki1a4sp9jmgcrlvr4avf25aasv7ov.apps.googleusercontent.com';
  const [profile, setProfile] = React.useState({});
  const [token, setToken] = React.useState(null);
  const [isLoggedin, setIsLoggedIn] = React.useState(false);

  const onSuccess = (response) => {
    const accessToken = response?.tokenObj?.access_token;
    const expiresAt = response?.tokenObj?.expires_at;
    const { profileObj } = response;

    setCookie(
      'ACCESS_TOKEN',
      accessToken ? `${accessToken}` : null,
      expiresAt,
    );
    setToken(response.tokenId);
    setProfile(profileObj);
    setIsLoggedIn(true);
    setLoading(false);
    localStorage.setItem('isLoggedin', true);
  };

  const onFailure = () => {
    deleteCookie('ACCESS_TOKEN');
    setIsLoggedIn(false);
    setLoading(false);
    localStorage.setItem('isLoggedin', false);
  };

  useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
  });

  const memoizedValue = React.useMemo(() => ({
    isLoggedin,
    onSuccess,
    onFailure,
    clientId,
    loading,
    profile,
    token,
  }), [isLoggedin, onSuccess, clientId, onFailure, profile, loading, token]);

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
