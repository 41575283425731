/* eslint-disable indent */
import weatherConstants from '../constants/weatherConstants';

const initialState = {
    weather: {
        loading: true,
    },
    news: {
        loading: true,
    },
};

const weatherReducer = (state = initialState, action = {}) => {
    const { payload, type, reqestTime } = action;

    switch (type) {
        case weatherConstants.GET_WEATHER_SUCCESS:
        case weatherConstants.GET_WEATHER_FAILED:
            return {
                ...state,
                weather: {
                    ...state?.weather,
                    data: payload,
                    reqestTime,
                },
            };

        case weatherConstants.GET_NEWS_SUCCESS:
        case weatherConstants.GET_NEWS_FAILED:
            return {
                ...state,
                news: {
                    ...state?.news,
                    data: payload,
                    reqestTime,
                },
            };

        case weatherConstants.LOADING:
            return {
                ...state,
                weather: {
                    ...state?.weather,
                    loading: payload,
                },
            };
        case weatherConstants.NEWS_LOADING:
            return {
                ...state,
                news: {
                    ...state?.news,
                    loading: payload,
                },
            };
        default:
            return state;
    }
};

export default weatherReducer;
