import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SideMenu from '../menus/SideMenu';
import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
  app: {
    maxWidth: '1300px',
    maxHeight: '860px',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    borderRadius: '14px',
    fontSize: '15px',
    fontWeight: '500',
    marginBottom: '80px',
    backgroundColor: 'var(--theme-bg-color)',
    backdropFilter: 'blur(15px)',
    [theme.breakpoints.down('lg')]: {
      height: '100%',
      marginBottom: 0,
      borderRadius: 0,
      maxHeight: '100%',
    },
  },
  fullscreen: {
    height: '100%',
    marginBottom: 0,
    width: '100%',
    borderRadius: 0,
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default function App() {
  const classes = useStyles();

  React.useEffect(() => {
    document.getElementById('root').style.padding = null;
  }, []);

  return (
    <div className={clsx(classes.app, classes.fullscreen)}>
      <div className="wrapper">
        <Hidden lgDown>
          <SideMenu />
        </Hidden>
        <div className="main-container">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <div className="overlay-app" />
    </div>
  );
}
