/* eslint-disable indent */
export default {
    GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
    GET_STATIONS_FAILED: 'GET_STATIONS_FAILED',
    SET_STATION: 'SET_STATION',
    SET_PLAYER_PROPERTIES: 'SET_PLAYER_PROPERTIES',
    PLAY_PREV_STATION: 'PLAY_PREV_STATION',
    PLAY_NEXT_STATION: 'PLAY_NEXT_STATION',
    LOADING: 'STATIONS_LOADING',
    FAV_UNFAV_IT: 'FAV_UNFAV_IT',
    GET_FAVS: 'GET_FAVS',
    SET_FAVS: 'SET_FAVS',
    OPEN_FAV_MENU: 'OPEN_FAV_MENU',
    GET_LANG_SUCCESS: 'GET_LANG_SUCCESS',
    GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
};
