import { Hidden } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSettingsContext } from '../contexts/SettingsContext';
import { menuItems } from '../data/menu';

function DesktopMenu() {
  const { dock } = useSettingsContext();
  if (!dock) return null;
  return (
    <Hidden>
      <div className="dock-container">
        <ul>
          {
                        menuItems.data.map(({ name, path, icon }) => (
                          <li key={path}>
                            <span>{name}</span>
                            <Link to={path}>
                              <img src={icon} alt={name} className="desktopIcon" />
                            </Link>
                          </li>
                        ))
                    }
        </ul>
      </div>
    </Hidden>

  );
}

export default DesktopMenu;
