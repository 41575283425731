/* eslint-disable no-inner-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  SwipeableDrawer, Typography, Grid, IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PlayArrowRounded, StopRounded } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import CssBaseline from '@mui/material/CssBaseline';
import { Global } from '@emotion/react';
import { StyledBox, Puller, TinyText } from '../../utils/HelperComponents';
import {
  setSetting, playPrevStation, playNextStation, setFavsFromStorage, setStation,
} from '../../reduxStore/actions/radioActions';

const useStyles = makeStyles({
  paper: {
    backgroundColor: '#9297b3e6 !important',
  },
});

export default function RadioPlayer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mainIconColor = '#fff';

  const drawerBleeding = 40;
  const {
    station, settings, favsSet, openFavMenu, favorites: { radio: savedStations },
  } = useSelector(({ radio }) => radio);

  const [open, setOpenDrawer] = React.useState(false);
  const [initial, setInitial] = React.useState(true);
  const playerRef = React.useRef();
  const {
    paused, muted,
  } = settings;

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  React.useEffect(() => {
    if (!initial) {
      setOpenDrawer(true);
    } else {
      setInitial(false);
    }
  }, [openFavMenu]);

  const playPrevious = () => {
    dispatch(playPrevStation());
  };

  const playNext = () => {
    dispatch(playNextStation());
  };

  navigator.mediaSession.setActionHandler('play', () => {
    dispatch(setSetting({ paused: false }));
  });
  navigator.mediaSession.setActionHandler('pause', () => {
    dispatch(setSetting({ paused: true }));
  });
  navigator.mediaSession.setActionHandler('stop', () => {
    dispatch(setSetting({ paused: true }));
  });
  navigator.mediaSession.setActionHandler('previoustrack', playPrevious);
  navigator.mediaSession.setActionHandler('nexttrack', playNext);

  React.useEffect(() => {
    if (!favsSet) {
      dispatch(setFavsFromStorage());
    }
  }, []);

  const selectStation = (data) => {
    dispatch(setStation(data));
    if (station?.id !== data.id) {
      dispatch(setSetting({ buffering: true }));
      dispatch(setSetting({ paused: false }));
    }
  };

  const [player, setPlayer] = React.useState(null);
  const [url, setURL] = React.useState(null);

  React.useEffect(() => {
    if (!player?.player?.isLoading) {
      setURL(station?.urlResolved);
    }
  }, [station, player?.player?.isLoading]);

  return (
    <>
      <ReactPlayer
        id="main-track"
        config={{
          file: {
            attributes: {
              id: 'audio_element_id',
            },
          },
        }}
        ref={playerRef}
        url={url}
        onReady={(pl) => setPlayer(pl)}
        hidden
        controls={false}
        playsinline
        playing={!paused}
        onBuffer={() => {
          dispatch(setSetting({ buffering: true }));
        }}
        onPause={() => {
          dispatch(setSetting({ paused: true }));
        }}
        onEnded={() => {
          dispatch(setSetting({ paused: true }));
        }}
        onBufferEnd={() => {
          dispatch(setSetting({ buffering: false }));
        }}
        volume={null}
        muted={muted}
      />
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        classes={{ paper: classes.paper }}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: false,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            color: '#fff !important',
            left: 0,
            backgroundColor: '#9297b3e1 !important',
          }}
        >
          <Puller />
          <Typography sx={{ p: 1, fontWeight: 'bold', color: '#ff705c' }}>&nbsp;</Typography>
        </StyledBox>
        <StyledBox
          sx={{
            height: '100%',
            overflow: 'auto',
            color: '#fff',
          }}
        >
          <div className="songs_list">
            <ul>
              {savedStations.length === 0 && <li>Please Add Some Favorite Stations</li>}
              {
                savedStations.map((station_, index) => {
                  const { name, id } = station_;
                  const active = id === station?.id;
                  return (
                    <li
                      key={station_.id || index}
                      className={active ? 'active' : undefined}
                      onKeyDown={() => selectStation(station_)}
                      onClick={() => selectStation(station_)}
                    >
                      <Grid container justifyContent="center">
                        <Grid item xs={11}>
                          {name}
                          <TinyText style={{ color: '#fff' }}>
                            {
                              [station_?.state, station_?.country].filter((item) => item).join(',')
                            }
                          </TinyText>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'right' }}>
                          <IconButton aria-label="Play Radio">
                            {
                              active
                                ? <StopRounded sx={{ fontSize: '1.5rem' }} className={`playbutton_small ${active && 'active_play_button'}`} htmlColor={mainIconColor} />
                                : <PlayArrowRounded sx={{ fontSize: '1.5rem' }} className="playbutton_small" htmlColor={mainIconColor} />
                            }
                          </IconButton>
                        </Grid>
                      </Grid>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
}
