/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { Box, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const StyledBox = styled(Box)(() => ({
  backgroundColor: 'transparent',
}));

export const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

// export const Widget = styled(Box)(({ theme }) => ({
//   padding: 16,
//   borderRadius: 16,
//   width: 400,
//   [theme.breakpoints.down('lg')]: {
//     width: 343,
//   },
//   maxWidth: '100%',
//   margin: 'auto',
//   position: 'relative',
//   justifyContent: 'center',
//   alignContent: 'center',
//   alignItems: 'center',
//   textAlign: 'center',
//   zIndex: 1,
//   display: 'flex',
//   flexDirection: 'column',
//   // overflow: "hidden"
//   // backgroundColor: 'rgba(0,0,0,0.2)',
// }));

export const Widget = styled(Card)(({ theme }) => ({
  padding: 16,
  margin: theme.spacing(1),
  borderRadius: 12,
  maxWidth: '100%',
  position: 'relative',
  zIndex: 1,
  backgroundColor: '#fff1',
  border: '1px solid #fff1',
//   backdropFilter: 'blur(40px)',
}));

export const ArrowTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export function Equalizer({ color }) {
  return (
    <svg className="equalizer" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 21 24" xmlSpace="preserve">
      <line stroke={color} strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="3" y1="22" x2="3" y2="2" />
      <line stroke={color} strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="8" y1="22" x2="8" y2="2" />
      <line stroke={color} strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="13" y1="22" x2="13" y2="2" />
      <line stroke={color} strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="18" y1="22" x2="18" y2="2" />
    </svg>
  );
}
