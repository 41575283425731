/* eslint-disable indent */
import musicConstants from '../constants/musicConstants';

const initialState = {
    songs: [],
    song: {},
    settings: {
        paused: true,
        buffering: false,
        error: null,
        muted: false,
        volume: 0.5,
        duration: 0,
        position: 0,
        seek: 0,
        seeking: false,
        playerRef: null,
    },
    openFavMenu: false,
    favsSet: false,
    loading: true,
};

const getNextSong = ({ song = {}, songs = [], settings = {} }) => {
    const totalStations = songs.length;
    const { index } = song;
    const nextStation = index + 1;
    if (nextStation < totalStations) {
        return {
            settings: {
                ...settings,
                buffering: true,
            },
            song: songs[nextStation],
        };
    }
    return {
        settings: {
            ...settings,
            buffering: true,
        },
        song: songs[0],
    };
};

const getPrevSong = ({ song = {}, songs = [], settings = {} }) => {
    const totalStations = songs.length;
    const { index } = song;
    const nextStation = index - 1;
    if (nextStation < 0) {
        return {
            settings: {
                ...settings,
                buffering: true,
            },
            song: songs[totalStations - 1],
        };
    }
    return {
        settings: {
            ...settings,
            buffering: true,
        },
        song: songs[nextStation],
    };
};

const MusicReducer = (state = initialState, action = {}) => {
    const { payload, type } = action;
    switch (type) {
        case musicConstants.SET_SONGS:
            return {
                ...state,
                songs: payload,
            };
        case musicConstants.LOADING:
            return {
                ...state,
                loading: payload,
            };
        case musicConstants.SET_SONG:
            return {
                ...state,
                song: action.payload,
            };
        case musicConstants.SET_MUSIC_PLAYER_PROPERTIES:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...(payload || {}),
                },
            };
        case musicConstants.PLAY_NEXT_SONG:
            return {
                ...state,
                ...(getNextSong(state)),
            };
        case musicConstants.PLAY_PREV_SONG:
            return {
                ...state,
                ...(getPrevSong(state)),
            };

        case musicConstants.OPEN_FAV_MENU:
            return {
                ...state,
                openFavMenu: payload,
            };

        default:
            return state;
    }
};

export default MusicReducer;
