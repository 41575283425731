/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import ContactCard from '../components/ContactCard';
import RouterWrapper from '../routes/RouterWrapper';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <RouterWrapper>
          <span style={{ fontSize: 30, fontWeight: 100, marginBottom: 20 }}>
            Something went wrong.
          </span>
          <ContactCard size="medium" tfi />
        </RouterWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
