/* eslint-disable react/prop-types */
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#9297b321 !important',
    borderRadius: '14px !important',
    border: '1px solid #fff1',
    color: '#fff',
    position: 'relative',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    transition: '1s ease',
    transitionDelay: '.9s',
    '& img': {
      width: '28px',
      height: '28px',
      borderRadius: '6px',
      marginRight: '12px',
      flexShrink: 0,
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    // '&:hover': {
    //   boxShadow: `0 0 1px #61c0bf,
    //             0 0 2px #61c0bf,
    //             0 0 3px #61c0bf`,
    // },
  },
  small: {
    padding: 8,
    fontSize: 12,
    border: ({ bg }) => (bg ? `2px solid ${bg}` : ''),
    '& img': {
      width: 20,
      height: 20,
      borderRadius: '6px',
      marginRight: '12px',
      flexShrink: 0,
    },
  },
  medium: {
    padding: 15,
    fontSize: 14,
    border: ({ bg }) => (bg ? `2px solid ${bg}` : ''),
    '& img': {
      width: 24,
      height: 24,
      borderRadius: '6px',
      marginRight: '12px',
      flexShrink: 0,
    },
  },
});

function IconCard({ children, size, ...rest }) {
  const classes = useStyles(rest);
  const itemClass = {
    small: classes.small,
    medium: classes.medium,
    large: {},
  };
  return (
    <Card elevation={2} sx={{ color: '#fff' }} className={clsx(classes.root, itemClass[size] || {})}>
      {children}
    </Card>
  );
}

IconCard.propTypes = {
  size: PropTypes.string,
};

IconCard.defaultProps = {
  size: 'large',
};

export default IconCard;
