/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import musicConstants from '../constants/musicConstants';
import { getSongDetails } from './arjitActions';

export const setSong = (song) => async (dispatch) => {
    dispatch({
        type: musicConstants.SET_SONG,
        payload: song,
    });
};

export const getSong = (song) => {
    getSongDetails(song);
};

export const setSetting = (setting) => async (dispatch) => {
    dispatch({
        type: musicConstants.SET_MUSIC_PLAYER_PROPERTIES,
        payload: setting,
    });
};

export const playPrevSong = () => async (dispatch) => {
    dispatch({
        type: musicConstants.PLAY_PREV_STATION,
        payload: 'prev',
    });
};

export const playNextSong = () => async (dispatch) => {
    dispatch({
        type: musicConstants.PLAY_NEXT_STATION,
        payload: 'next',
    });
};

export const openFavMenuAction = (openState) => async (dispatch) => {
    dispatch({
        type: musicConstants.OPEN_FAV_MENU,
        payload: openState,
    });
};
