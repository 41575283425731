/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {
  Card, Box, IconButton, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  SkipNext,
  VolumeUpRounded,
  VolumeOffRounded,
  FolderSpecial,
  Radio as RadioIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getStations, setSetting, playPrevStation, playNextStation, openFavMenuAction,
} from '../../reduxStore/actions/radioActions';
import useLocationHook from '../../customhooks/LocationCustomHooks';
import SkeletonLoader from '../SkeletonLoader';
import FavStation from './FavStation';
import MainPlayButton from './MainPlayButton';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent !important',
    borderRadius: '14px !important',
    border: '1px solid #fff1',
    transition: '0.3s ease',
    textAlign: 'center',
    minHeight: '165px',
    padding: 10,
    position: 'relative',
  },
  inner: {
    backgroundColor: '#fff1 !important',
    border: '1px solid #fff1',
    textAlign: 'center',
    borderRadius: '14px !important',
  },
  radioimage: {
    height: 100,
    width: 100,
    marginBottom: 10,
    marginTop: 20,
    borderRadius: '12px !important',
  },
  icon: {
    position: 'absolute',
    top: 10,
    color: '#fff9',
  },
  radiotitle: {
    fontSize: 20,
    fontWeight: 300,
  },
  radiobg: {
    background: '#fff1',
    border: '1px solid #fff1',
    color: '#fff',
    backgroundColor: '#fff1 !important',
    textAlign: 'center',
    borderRadius: 13,
    padding: 10,
  },
  fab: {
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    borderRadius: '50%',
    color: '#fff !important',
    border: '1px solid #fff1',
  },
}));

export default function MiniRadio() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useLocationHook();

  const textColor = '#fff';
  const {
    stations, station, loading, settings, openFavMenu,
  } = useSelector(({ radio }) => radio);

  React.useEffect(() => {
    if (stations?.length === 0) {
      dispatch(getStations({
        countryCode: location?.country_code || 'IN',
        language: 'telugu',
        limit: 10,
        order: 'votes',
        reverse: true,
        removeDuplicates: true,
      }));
    }
  }, []);

  const {
    error, muted,
  } = settings;

  const playPrevious = () => {
    dispatch(playPrevStation());
  };

  const playNext = () => {
    dispatch(playNextStation());
  };

  return (
    <Card elevation={2} className={classes.root}>
      <canvas id="canvas_id_mini" />
      {loading && isEmpty(station)
        ? <SkeletonLoader /> : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span style={{
                textAlign: 'center',
                color: textColor,
              }}
              >
                <div className={classes.radiotitle}>
                  {error ? 'Station Error' : station?.name}
                </div>
                <span spacing={1} direction="row">
                  <span>
                    {error ? 'Please Try Again' : [station?.state, station?.country].filter((d) => d).join(',')}
                  </span>
                </span>
                <Box
                  sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
                  }}
                >
                  <IconButton aria-label="next song" style={{ position: 'relative', top: '40%', left: 10 }} onClick={playPrevious}>
                    <SkipNext
                      fontSize="large"
                      style={{ transform: 'rotate(180deg)', color: 'red !important' }}
                      sx={{ color: `${textColor} !important` }}
                      className={classes.fab}
                    />
                  </IconButton>
                  <MainPlayButton textColor={textColor} />
                  <IconButton aria-label="next song" style={{ position: 'relative', top: '40%', right: 10 }} onClick={playNext}>
                    <SkipNext
                      fontSize="large"
                      sx={{ color: `${textColor} !important` }}
                      className={classes.fab}
                    />
                  </IconButton>
                </Box>
                <FavStation station={station} textColor={textColor} />
                <IconButton
                  aria-label="next song"
                  style={{
                    color: textColor,
                  }}
                  onClick={() => {
                    dispatch(setSetting({ muted: !muted }));
                  }}
                >
                  {
                muted ? <VolumeOffRounded fontSize="medium" />
                  : <VolumeUpRounded fontSize="medium" />
              }
                </IconButton>
                <IconButton
                  aria-label="fav list"
                  style={{
                    color: textColor,
                  }}
                  onClick={() => dispatch(openFavMenuAction(!openFavMenu))}
                >
                  <FolderSpecial fontSize="medium" />
                </IconButton>
                <IconButton
                  aria-label="open radio page"
                  style={{
                    color: textColor,
                  }}
                >
                  <Link to="/radio">
                    <RadioIcon fontSize="medium" sx={{ color: textColor }} />
                  </Link>
                </IconButton>
              </span>
            </Grid>
          </Grid>
        )}
    </Card>
  );
}
