/* eslint-disable indent */
export default {
    GET_SONG_DATA_SUCCESS: 'GET_SONG_DATA_SUCCESS',
    GET_SONG_DATA_FAILURE: 'GET_SONG_DATA_FAILURE',
    GET_SONGS_SUCCESS: 'GET_SONGS_SUCCESS',
    GET_SONGS_FAILURE: 'GET_SONGS_FAILURE',
    GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_DETAILS_FAILURE',
    LOADING: 'LOADING_ARJIT_DATA',
};
