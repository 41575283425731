/* eslint-disable react/prop-types */
import React from 'react';

const SettingsContext = React.createContext({});

export const useSettingsContext = () => React.useContext(SettingsContext);

export function SettingsContextWrapper({ children }) {
  const boxed = false;
  const dock = false;
  const memoizedValue = React.useMemo(() => ({ boxed, dock }), [boxed, dock]);
  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
