/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  SwipeableDrawer, Typography, Grid, IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PlayArrowRounded, StopRounded } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import CssBaseline from '@mui/material/CssBaseline';
import { Global } from '@emotion/react';
import { StyledBox, Puller, TinyText } from '../../utils/HelperComponents';
import {
  setSetting, playPrevSong, playNextSong, getSong, setSong,
} from '../../reduxStore/actions/musicActions';
import { useAuthContext } from '../../contexts/AuthContext';

const useStyles = makeStyles({
  paper: {
    backgroundColor: '#9297b3e6 !important',
  },
});

export default function ArjitPlayer() {
  const {
    isLoggedin,
  } = useAuthContext();

  const dispatch = useDispatch();

  const {
    song, settings, songs,
  } = useSelector(({ music }) => music);

  const playerRef = React.useRef();
  const [player, setPlayer] = React.useState(null);
  const [state, setState] = React.useState({
    url: null,
    playing: true,
    controls: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    loop: true,
    ref: playerRef,
    hidden: true,
    id: 'main-track',
    config: {
      file: {
        attributes: {
          id: 'audio_element_id_music',
        },
      },
    },
    playsinline: true,
  });

  React.useEffect(() => {
    const {
      paused, muted, volume, position, bufferring, seek, seeking,
    } = settings;
    setState({
      ...state,
      playing: !paused,
      muted,
      volume,
      played: position,
      seek,
    });
  }, [settings]);

  React.useEffect(() => {
    if (!player?.player?.isLoading) {
      setState({
        ...state,
        url: song?.media_url,
        loaded: 0,
        played: 0,
      });
      dispatch(setSetting({ paused: false }));
    }
  }, [song, player?.player?.isLoading]);

  if (!isLoggedin) return null;

  return (
    <ReactPlayer
      onReady={(pl) => {
        setPlayer(pl);
        dispatch(setSetting({ playerRef: pl }));
      }}
      onProgress={(val) => dispatch(setSetting({ position: val.playedSeconds }))}
      onDuration={(val) => {
        dispatch(setSetting({ duration: val }));
      }}
      onBuffer={() => {
        dispatch(setSetting({ buffering: true }));
      }}
      onPause={() => {
        dispatch(setSetting({ paused: true }));
      }}
      onEnded={() => {
        dispatch(setSetting({ paused: true }));
      }}
      onBufferEnd={() => {
        dispatch(setSetting({ buffering: false }));
      }}
      {...state}
    />
  );
}
