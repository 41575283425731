/* eslint-disable no-console */
import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import './index.scss';
import Hidden from '@mui/material/Hidden';
import { Provider } from 'react-redux';
import theme from './theme';
import RoutingLayer from './routes/RoutingLayer';
import BGVideo from './components/BgVideo';
import store from './reduxStore/store';

export default function App() {
  return (
    <Provider store={store}>
      <Hidden lgDown>
        <BGVideo />
      </Hidden>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RoutingLayer />
      </ThemeProvider>
    </Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
