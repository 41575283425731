/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Hidden } from '@mui/material';

export default function RouterWrapper({ children, ...props }) {
  return (
    <div className="content-wrapper" {...props}>
      {children}
      <Hidden lgUp>
        <div style={{ paddingBottom: 50 }} />
      </Hidden>
    </div>
  );
}
