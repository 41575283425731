/* eslint-disable indent */
import { uniq } from 'lodash';
import arjitConstants from '../constants/arjitConstants';

const initialState = {
    details: {},
    playlists: [],
    songs: [],
    songdetails: {},
    loading: false,
    page: 0,
};

const arjitReducer = (state = initialState, action = {}) => {
    const { payload, type, id } = action;
    switch (type) {
        case arjitConstants.GET_DETAILS_SUCCESS:
        case arjitConstants.GET_DETAILS_FAILURE:
            return {
                ...state,
                details: payload,
                playlists: payload?.dedicated_artist_playlist || [],
                songs: payload?.topSongs || [],
                singles: payload?.singles || [],
                latestReleases: payload?.latest_release || [],
                loading: action.loading,
            };

        case arjitConstants.GET_SONGS_SUCCESS:
        case arjitConstants.GET_SONGS_FAILURE:
            return {
                ...state,
                songs: uniq([...state.songs, ...payload], (d) => d.id),
                // .filter((d) => d.duration < 600),
                loading: action.loading,
                page: action.page,
            };

        case arjitConstants.GET_SONG_DATA_SUCCESS:
        case arjitConstants.GET_SONG_DATA_FAILURE:
            return {
                ...state,
                songdetails: {
                    ...state.songdetails,
                    [id]: payload,
                },
                loading: action.loading,
            };

        case arjitConstants.LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default arjitReducer;
