/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import {
    AccountTree, Call, Home, Cloud, AccessTime, Numbers, Radio,
} from '@mui/icons-material';

export const menuItems = {
    data: [
        {
            name: 'Home',
            path: '/',
            FaIcon: Home,
            category: 'resume',
        },
        {
            name: 'Portfolio',
            path: '/portfolio',
            FaIcon: AccountTree,
            category: 'resume',
        },
        {
            name: 'Contact',
            path: '/contact',
            FaIcon: Call,
            category: 'resume',
        },
        {
            name: 'Global Radio',
            path: '/radio',
            FaIcon: Radio,
            category: 'apps',
        },
        {
            name: 'Weather',
            path: '/weather',
            FaIcon: Cloud,
            category: 'apps',
        },
        {
            name: 'World Clock',
            path: '/worldclock',
            FaIcon: AccessTime,
            category: 'apps',
        },
        {
            name: 'Number System',
            path: '/numbersystem',
            FaIcon: Numbers,
            category: 'apps',
        },
    ],
};
