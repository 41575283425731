/* eslint-disable radix */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
export function formatDuration(value) {
  let s = parseInt(value, 10);
  const formatted = (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
  return formatted;
}

export const formatNumber = (value, metric = '°C') => `${Math.ceil(value)}${metric || ''}`;

export const formatText = (value) => value;

export const getLocationInfo = async (location) => {
  const res = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${location.latitude}+${location.longitude}&key=9bace1c156774a7094bed7e538846264&no_annotations=1&language=en`);
  return {
    ...location,
    city: res?.data?.results?.[0].components?.county,
    country_code: res?.data?.results?.[0].components?.country_code.toUpperCase(),
    country: res?.data?.results?.[0].components?.country,
    state_district: res?.data?.results?.[0].components?.state_district,
  };
};

export function IsJsonString(str) {
  try {
    const json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
}

export const getFavInitials = () => {
  const stored = localStorage.getItem('favorites');
  if (IsJsonString(stored)) {
    const parsed = JSON.parse(stored);
    return {
      radioIds: parsed?.radioIds?.length ? parsed.radioIds : [],
      radio: parsed?.radio?.length ? parsed.radio : [],
      music: parsed?.music?.length ? parsed.music : [],
    };
  }
  return {
    radioIds: [],
    radio: [],
    music: [],
  };
};

export const decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
}());

export function deleteCookie(cname) {
  document.cookie = `${cname}=;path=/`;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date(exdays);
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
