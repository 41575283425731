import { useEffect, useState } from 'react';
import axios from 'axios';

const useLocationHook = () => {
  const getLocation = () => {
    let data = localStorage.getItem('location');
    data = data ? JSON.parse(data) : null;
    return data;
  };

  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(getLocation());

  const storeLocation = (loc) => {
    const finalObj = {
      ...loc, id: 'location1', formattedAddress: loc?.city, zoneabbr: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    setLocation(finalObj);
  };

  const getData = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      storeLocation({
        ...res?.data || {},
        state_district: res?.data?.city,
      });
      setLoading(false);
    } catch {
      storeLocation({
        country_code: 'IN',
        country_name: 'India',
        city: 'Visakhapatnam',
        postal: '110001',
        latitude: 17.686815,
        longitude: 83.218483,
        state_district: 'Visakhapatnam',
        state: 'Andhra Pradesh',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const locationLocal = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        try {
          const res = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${locationLocal.latitude}+${locationLocal.longitude}&key=9bace1c156774a7094bed7e538846264&no_annotations=1&language=en`);
          storeLocation({
            ...locationLocal,
            city: res?.data?.results?.[0].components?.county,
            country_code: res?.data?.results?.[0].components?.country_code.toUpperCase(),
            country: res?.data?.results?.[0].components?.country,
            state_district: res?.data?.results?.[0].components?.state_district,
          });
          setLoading(false);
        } catch (e) {
          storeLocation({
            ...locationLocal,
          });
          setLoading(false);
        }
      }, (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          getData();
        }
      });
    } else {
      setLoading(false);
    }
  }, []);
  return { loading, location };
};

export default useLocationHook;
