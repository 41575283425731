/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Layout from '../layouts/layout';
import RadioPlayer from '../components/radio/RadioPlayer';
import ArjitPlayer from '../components/arjit/ArjitPlayer';
import DesktopMenu from '../menus/DesktopMenu';
import { MobileMenuWrapper } from '../menus/MobileMenu';
import ErrorBoundary from '../layouts/ErrorBoundary';
import { SettingsContextWrapper } from '../contexts/SettingsContext';
// import ProtectedRoute from './ProtectedRoute';
import { AuthContextWrapper } from '../contexts/AuthContext';

const Home = lazy(() => import('./Home'));
const Resume = lazy(() => import('./Resume'));
const Portfolio = lazy(() => import('./Portfolio'));
const Apps = lazy(() => import('./Apps'));
const Contact = lazy(() => import('./Contact'));
const Settings = lazy(() => import('./Settings'));
const Credits = lazy(() => import('./Credits'));
const Weather = lazy(() => import('./Weather'));
const WorldClock = lazy(() => import('./WorldClock'));
const NumberSystem = lazy(() => import('./NumberSystem'));
const Radio = lazy(() => import('./Radio'));
// const ArjitWorld = lazy(() => import('./ArjitWorld'));
const Login = lazy(() => import('./Login'));

function WeatherWrap() {
  return (
    <ErrorBoundary>
      <Weather />
    </ErrorBoundary>
  );
}

function RadioWrap() {
  return (
    <ErrorBoundary>
      <Radio />
    </ErrorBoundary>
  );
}

function RoutingLayer() {
  return (
    <AuthContextWrapper>
      <RadioPlayer />
      <ArjitPlayer />
      <BrowserRouter>
        <SettingsContextWrapper>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="resume" element={<Resume />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="apps" element={<Apps />} />
              <Route path="contact" element={<Contact />} />
              <Route path="credits" element={<Credits />} />
              <Route path="weather" element={<WeatherWrap />} />
              <Route path="worldclock" element={<WorldClock />} />
              <Route path="settings" element={<Settings />} />
              <Route path="numbersystem" element={<NumberSystem />} />
              <Route path="radio" element={<RadioWrap />} />
              <Route
                path="*"
                element={(
                  <main style={{ padding: '1rem', color: '#fff' }}>
                    <p>There&apos;s nothing here!</p>
                  </main>
                  )}
              />
            </Route>
            <Route path="/empty" />
          </Routes>
          <DesktopMenu />
          <MobileMenuWrapper />
        </SettingsContextWrapper>
      </BrowserRouter>
    </AuthContextWrapper>
  );
}

export default RoutingLayer;
