/* eslint-disable indent */
export default {
    SET_SONG: 'SET_MUSIC_SONG',
    SET_SONGS: 'SET_MUSIC_SONGS',
    LOADING: 'MUSIC_LOADING',
    SET_MUSIC_PLAYER_PROPERTIES: 'SET_MUSIC_PLAYER_PROPERTIES',
    PLAY_NEXT_STATION: 'PLAY_NEXT_SONG',
    PLAY_PREV_SONG: 'PLAY_PREV_SONG',
    OPEN_FAV_MENU: 'OPEN_MUSIC_FAV_MENU',
};
