/* eslint-disable indent */
import { uniq } from 'lodash';
import radioConstants from '../constants/radioConstants';

const initialState = {
    stations: [],
    station: {},
    settings: {
        paused: true,
        buffering: false,
        error: null,
        muted: false,
    },
    openFavMenu: false,
    favsSet: false,
    favorites: {
        radioIds: [],
        radio: [],
        music: [],
    },
    languages: [{ name: 'telugu', iso_639: 'te', stationcount: 10 }],
    countries: [{ name: 'IN', stationcount: 222 }],
    loading: true,
};

const getNextStation = ({ station = {}, stations = [], settings = {} }) => {
    const totalStations = stations.length;
    const { index } = station;
    const nextStation = index + 1;
    if (nextStation < totalStations) {
        return {
            settings: {
                ...settings,
                buffering: true,
            },
            station: stations[nextStation],
        };
    }
    return {
        settings: {
            ...settings,
            buffering: true,
        },
        station: stations[0],
    };
};

const getPrevStation = ({ station = {}, stations = [], settings = {} }) => {
    const totalStations = stations.length;
    const { index } = station;
    const nextStation = index - 1;
    if (nextStation < 0) {
        return {
            settings: {
                ...settings,
                buffering: true,
            },
            station: stations[totalStations - 1],
        };
    }
    return {
        settings: {
            ...settings,
            buffering: true,
        },
        station: stations[nextStation],
    };
};

const RadioReducer = (state = initialState, action = {}) => {
    const { payload, type } = action;
    switch (type) {
        case radioConstants.GET_STATIONS_SUCCESS:
        case radioConstants.GET_STATIONS_FAILED:
            return {
                ...state,
                stations: payload,
                loading: action.loading,
            };
        case radioConstants.LOADING:
            return {
                ...state,
                loading: payload,
            };
        case radioConstants.SET_STATION:
            return {
                ...state,
                station: action.payload,
            };
        case radioConstants.GET_LANG_SUCCESS:
            return {
                ...state,
                languages: action.payload,
            };

        case radioConstants.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload,
            };

        case radioConstants.SET_PLAYER_PROPERTIES:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...(payload || {}),
                },
            };
        case radioConstants.PLAY_NEXT_STATION:
            return {
                ...state,
                ...(getNextStation(state)),
            };
        case radioConstants.PLAY_PREV_STATION:
            return {
                ...state,
                ...(getPrevStation(state)),
            };

        case radioConstants.OPEN_FAV_MENU:
            return {
                ...state,
                openFavMenu: payload,
            };

        case radioConstants.SET_FAVS:
            return {
                ...state,
                favorites: payload,
                favsSet: true,
            };
        case radioConstants.FAV_UNFAV_IT: {
            const { station, event } = payload;
            let updatedIds = [];
            if (event === 'add') {
                updatedIds = uniq([station?.id, ...state.favorites.radioIds]);
            } else {
                updatedIds = state.favorites.radioIds.filter((d) => d !== station?.id);
            }
            const radios = [station, ...state.favorites.radio]
                .filter((d) => updatedIds.includes(d.id));
            localStorage.setItem('favorites', JSON.stringify({
                ...state.favorites,
                radioIds: updatedIds,
                radio: radios,
            }));
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    radioIds: updatedIds,
                    radio: radios,
                },
            };
        }

        default:
            return state;
    }
};

export default RadioReducer;
