/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Hidden, Tab, Tabs, AppBar,
} from '@mui/material';
import { useLocation, NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { menuItems } from '../data/menu';
import Arjit from './ArjitIcon';

const useStyles = makeStyles({
  box: {
    color: '#fff',
    zIndex: 100,
    width: '100%',
    backgroundColor: '#9297b321 ',
    boxShadow: '0 2px 1px hsla(0, 0%, 100%, 0.2) inset',
  },
  icon: {
    color: 'white',
  },
  text: {
    display: 'block;',
    color: '#fff;',
    fontSize: 10,
    textTransform: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '10rem',
  },
  tab: {
    width: 30,
    minWidth: 30,
  },
});

export default function MobileMenu() {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState('/');

  useEffect(() => {
    setValue(menuItems.data.find((d) => d.path === location?.pathname)?.path);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBar position="sticky" sx={{ top: 'auto', bottom: 0 }} elevation={5} color="primary" style={{ background: '#6c1122' }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.box}
      >
        <Tabs
          classes={{ root: classes.root, scroller: classes.scroller }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: '#f9fafb' } }}
          aria-label="Mobile Menu List"
        >
          {
            menuItems.data.map((d) => {
              const { name, path, FaIcon } = d;
              return (
                <Tab
                  key={path}
                  value={path}
                  classes={{ root: classes.tab }}
                  style={{ marginBottom: -6 }}
                  label={(
                    <>
                      {
                        path === '/arjitworld'
                          ? <Arjit fill="#fff" />
                          : <FaIcon className={classes.icon} />
                      }
                      <span className={classes.text}>
                        {name}
                      </span>
                    </>
                  )}
                  component={NavLink}
                  to={path}
                />
              );
            })
          }
        </Tabs>
      </Box>
    </AppBar>
  );
}

export function MobileMenuWrapper() {
  return (
    <Hidden lgUp>
      <MobileMenu />
    </Hidden>
  );
}
