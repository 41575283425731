/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PropTypes from 'prop-types';
import gmailPNG from '../assets/images/icons/gmail.svg';
import phonePNG from '../assets/images/icons/phone.svg';
import githubPNG from '../assets/images/icons/github.svg';
import linkedinPNG from '../assets/images/icons/linkedin.svg';
import IconCard from './IconCard';

const useStyles = makeStyles({
  iconContainer: {
    position: 'absolute !important',
    right: 0,
    height: 10,
    '& :hover': {
      color: 'skyblue',
    },
  },
  icon: {
    color: 'lightgray',
  },
  link: {

  },
});

function ContactItem({
  size, image, text, alt, url,
}) {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);

  const copyText = (text_) => {
    navigator.clipboard.writeText(text_);
  };

  const changeIcon = (text_) => {
    setClicked(true);
    copyText(text_);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setClicked(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <IconCard size={size}>
      <span>
        <img src={image} alt={alt} />
        <a href={url} target="_blank" className="link" rel="noreferrer">{text}</a>
        <IconButton color="primary" onClick={() => changeIcon(text)} aria-label="upload picture" className={classes.iconContainer} component="span">
          {
            clicked ? <DoneAllIcon fontSize="small" className={classes.icon} /> : <ContentCopyIcon fontSize="small" className={classes.icon} />
          }
        </IconButton>
      </span>
    </IconCard>
  );
}
function ContactCard({ size, sizeProps }) {
  return (
    <Grid container spacing={2}>
      <Grid item {...sizeProps}>
        <ContactItem size={size} image={phonePNG} text="+91 9441841403" alt="Phone" url="tel:+919441841403" />
      </Grid>
      <Grid item {...sizeProps}>
        <ContactItem size={size} image={gmailPNG} text="satyarajukits@gmail.com" alt="Gmail" url="mailto: satyarajukits@gmail.com" />
      </Grid>
      <Grid item {...sizeProps}>
        <ContactItem size={size} image={githubPNG} text="@rajapolavarapu" alt="Github" url="https://github.com/rajapolavarapu" />
      </Grid>
      <Grid item {...sizeProps}>
        <ContactItem size={size} image={linkedinPNG} text="@polavarapuraja" alt="LinkedIn" url="https://www.linkedin.com/in/polavarapuraja/" />
      </Grid>
    </Grid>
  );
}

ContactItem.propTypes = {
  size: PropTypes.string,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ContactItem.defaultProps = {
  size: 'small',
};

ContactCard.propTypes = {
  size: PropTypes.string,
  sizeProps: PropTypes.any,
};

ContactCard.defaultProps = {
  size: 'small',
  sizeProps: { xs: 12, md: 6, lg: 3 },
};
export default ContactCard;
