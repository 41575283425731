import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginTop: ({ mt }) => (mt || 20),
    display: 'flex',
  },
});

function BreakGap(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root} />
  );
}

export default BreakGap;
