/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box, Fab, CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PlayArrowRounded as PlayIcon,
  Pause as PauseIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  setSetting,
} from '../../reduxStore/actions/radioActions';

const useStyles = makeStyles(() => ({
  fab: {
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    borderRadius: '50%',
    color: '#fff !important',
    border: '1px solid #fff1',
  },
}));

export default function MainPlayButton({ fontSize = '2.5rem', textColor = '#fff' }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    settings,
  } = useSelector(({ radio }) => radio);

  const {
    buffering,
    paused,
  } = settings;

  const controlMedia = (buffer) => {
    if (buffer) {
      dispatch(setSetting({ buffering: buffer }));
    }
    dispatch(setSetting({ paused: !paused }));
    dispatch(setSetting({ buffering: paused }));
  };

  const RadioMediaIcon = paused ? PlayIcon : PauseIcon;

  return (
    <Box sx={{ m: 2, position: 'relative' }}>
      <Fab
        onClick={() => {
          controlMedia(true);
        }}
        className={clsx(classes.fab, paused ? '' : 'playing')}
        style={{
          background: 'transparent',
          border: '1px solid #fff1',
        }}
        aria-label="play"
      >
        <RadioMediaIcon sx={{ fontSize, color: textColor }} />
      </Fab>
      {buffering && (
        <CircularProgress
          size={58}
          sx={{
            color: '#fff',
            fontSize: 1,
            position: 'absolute',
            top: 0,
            left: -1,
            zIndex: -1,
          }}
        />
      )}
    </Box>
  );
}
