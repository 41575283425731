import { Stack, Skeleton } from '@mui/material';

export default function SkeletonLoader() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" />
      <Skeleton variant="rectangular" height={118} />
      <Skeleton variant="text" />
    </Stack>
  );
}
