import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import '../scss/loading.scss';

const useStyles = makeStyles({
  loading: {
    fontSize: 60,
    fontFamily: "'Montserrat', sans-serif",
    color: '#fff6',
    fontWeight: 800,
    textAlign: 'center',

  },
});

function Loading() {
  const classes = useStyles();
  return (
    <section>
      <div className={clsx(classes.loading, 'loading02')}>
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
      </div>
    </section>
  );
}
export default Loading;
