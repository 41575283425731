/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { IconButton } from '@mui/material';
import { StarBorder, Star } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { favIt } from '../../reduxStore/actions/radioActions';

export default function FavStation({ station, textColor = '#fff' }) {
  const dispatch = useDispatch();

  const { radioIds } = useSelector(({ radio: { favorites: { radioIds } } }) => ({ radioIds }));
  const isPresent = radioIds.includes(station?.id);
  const makeFav = () => {
    if (!isPresent) {
      dispatch(favIt({ station, event: 'add' }));
    } else {
      dispatch(favIt({ station, event: 'remove' }));
    }
  };

  return (
    <IconButton
      aria-label="fav list"
      style={{
        color: textColor,
      }}
      onClick={makeFav}
    >
      {
        isPresent ? <Star fontSize="medium" />
          : <StarBorder fontSize="medium" />
      }
    </IconButton>
  );
}
