import React from 'react';
import Video from '../assets/videos/BG_Compressed.mp4';

function BGVideo() {
  return (
    <div className="video-bg">
      <video width="320" height="240" src={Video} autoPlay loop muted />
    </div>
  );
}
export default BGVideo;
